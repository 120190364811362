<template>
  <div class="RuleModal">
    <b-modal id="rule-by-id" hide-footer>
      <template #modal-title>
        กติกา
      </template>
      <div class="d-block text-center">
        <div style="width: 100%; overflow-y: scroll;height: 80vh;" v-if="IMAGE_PATH">
          <img :src="IMAGE_PATH + 'rule_'+lot_type_id+'.png'" style="width: 100%">
        </div>
        <div v-html="advice" style="width: 100%; overflow-y: scroll;height: 80vh;" v-else/>
      </div>
      <b-button  class="btn btn btn-danger mt-3" block @click="$bvModal.hide('rule-by-id')">ฉันเข้าใจและยอมรับ</b-button>
    </b-modal>
    <!-- <div class="modal fade" id="rule-by-id" tabindex="-1" role="dialog" aria-labelledby="rule-by-id" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="border-radius: 10px">
          <div class="modal-header bg-dark-blue text-white">
            <h5 class="modal-title">กฏและกติกา</h5>
            <button type="button" class="text-white close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-html="advice" style="width: 100%" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-dark-blue" data-dismiss="modal">ฉันเข้าใจและยอมรับ</button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      advice: state => state.lotteryModule.inquiryRoundInfomationByID.advice,
      lot_type_id: state => state.lotteryModule.inquiryRoundInfomationByID.lot_type_id
    })
  },
  data () {
    return {
      IMAGE_PATH: process.env.VUE_APP_LOTTO_IMAGE_PATH
    }
  }
}
</script>

<style>
.img-100 img{
  width: 100% !important
}
.bg-dark-blue {
  background: rgba(31, 49, 84, 1);
}
.btn-dark-blue {
  background: rgba(31, 49, 84, 1);
  color: white;
}
</style>

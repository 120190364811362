<template>
  <div class="numberSetTang">
    <div id="section-content" class="container">
      <div class="bar-back">
        <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-1 px-2 xtarget col-lotto d-flex flex-row justify-content-between mb-1 pb-0">
            <div class="lotto-title">
                <h4><i class="fas fa-ticket-alt"></i> {{ lotteryInformation ? lotteryInformation.group_name : '' }}</h4>
            </div><!-- end title --->
            <a v-b-modal.rule-by-id href="#" class="btn btn-outline-secondary btn-sm mr-1" >กติกา
                &amp; วิธีเล่น</a>
        </div>
        <div v-if="isLoading">
          <div class="row px-0 mb-1 mx-0">
             <div class="col-4 p-1 pb-0">
            <router-link
              :to="`/member/lottery/lottoset/${id}`"
              style="align-items: center"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column font_size_0_9em pt-2 pb-2">
              <i class="fas fa-award"></i>
              ผล{{ lotteryInformation ? lotteryInformation.group_name : '' }}
            </router-link>
          </div>
          <div class="col-4 p-1 pb-0">
            <router-link
              style="align-items: center"
              :to="`/member/lottery/lottoset/${id}/tang`"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column font_size_0_9em pt-2 pb-2 active">
              <i class="fas fa-calculator"></i>
              แทง{{ lotteryInformation ? lotteryInformation.group_name : '' }}
            </router-link>
          </div>
           <div class="col-4 p-1 pb-0">
            <router-link
              style="align-items: center"
              :to="`/member/lottery/lottoset/${id}/poy`"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column font_size_0_9em pt-2 pb-2">
              <i class="fas fa-calculator"></i>
              รายการที่แทง
            </router-link>
          </div>
          </div>
          <div class="w-100 my-2 border-bottom"></div>
          <div class="bgwhitealpha shadow-sm rounded p-2 mb-1 xtarget col-lotto d-flex flex-column flex-sm-column flex-md-row justify-content-between">
            <h4 class="mb-0 text-center"><i class="fas fa-star"></i> แทง{{ lotteryInformation ? lotteryInformation.group_name : '' }}</h4>
            <div class="d-flex flex-row flex-sm-row flex-md-row-reverse justify-content-between justify-content-sm-between justify-content-md-end">
              <div class="p-1" v-if="lotteryInformation.status == 1  && !this.getIsClose(lotteryInformation.date.date_close)"><span class="badge badge-dark font-weight-light">ประจำวันที่ </span> {{ lotteryInformation.title }}</div>
              <div class="p-1"  v-else><font class="text-primary">{{ lotteryInformation ? lotteryInformation.group_name : '' }}ยังไม่เปิดกรุณารอรอบต่อไป</font></div>
            </div>
          </div>
          <div class="bg-white shadow-sm rounded py-2 px-1 mb-5">
            <div class="w-100 p-1 mt-2">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                  <div class="group__keyboard">
                    <div class="num-pad box__show-number">
                      <h4 class="text-center">ระบุตัวเลข 4 หลัก</h4>
                      <div class="label-number lists d-flex justify-content-center">
                        <div id="bet_num" v-if="number.split('').length > 0">
                          <label class="number" v-for="(item, index) in type" :key="index">{{
                            number.split('')[index] ? number.split('')[index] : ' '
                          }}</label>
                        </div>
                        <div id="bet_num" v-else>
                          <label class="number" v-for="index in type" :key="index"> </label>
                        </div>
                      </div>
                      <div class="key-pad box__keyboard">
                        <div class="row p-2">
                          <div
                            class="col-3"
                            :key="index"
                            v-for="(number, index) in [
                              1,
                              2,
                              3,
                              'delete',
                              4,
                              5,
                              6,
                              'cancel',
                              7,
                              8,
                              9,
                              'clear',
                              null,
                              0,
                              null,
                              undefined
                            ]"
                          >
                            <button
                              v-if="number === 'cancel'"
                              class="btn btn-secondary btn-block btn-cancel warning btn-cancle-last-add-num"
                              @click="removeLast()"
                            >
                              <span>ยกเลิก</span><span>ล่าสุด</span>
                            </button>
                            <button
                              v-else-if="number === 'clear'"
                              class="btn btn-dark btn-block btn-reset"
                              @click="clearList()"
                            >
                              <span>ล้าง</span><span>ข้อมูล</span>
                            </button>
                            <button
                              v-else-if="number === 'delete'"
                              class="btn btn-primary btn-block"
                              data-id="delete"
                              @click="deleteLast()"
                            >
                              <i class="fas fa-backspace"></i>
                            </button>
                            <div v-else-if="number === undefined"></div>
                            <button
                              v-else
                              :class="number === null ? 'btn btn-blank' : 'btn btn-outline-primary btn-block'"
                              :disabled="number === null ? true : false"
                              :data-id="number"
                              @click="pushNumber(number)"
                            >
                              {{ number }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6">
                  <div class="row p-0 m-0">
                    <div class="col-12 p-1">
                      <div class="w-100 rounded border p-2">
                        <div class="d-flex justify-content-between align-items-center">
                            <span><i class="fas fa-clipboard-list"></i> รายการแทง</span>
                            <span class="badge badge-secondary font-weight-normal">ราคาชุดละ {{ this.priceLottoSet }} บาท</span>
                        </div>
                        <div class="w-100 border border-secondary border-bottom-0 m-1"></div>
                        <div class="d-flex justify-content-between">
                          <span>ชุดเลข</span>
                          <span>จำนวนชุด</span>
                        </div>
                        <span id="lottery_list">
                          <div
                            class="d-flex justify-content-between align-items-center"
                            v-for="(item, index) in numberList"
                            :key="index"
                          >
                            <h1 style="letter-spacing: 10px">{{ item.number }}</h1>
                            <div class="input-group w-50">
                              <input
                                type="tel"
                                class="form-control text-right lottery_amount"
                                placeholder=""
                                aria-label=""
                                aria-describedby="basic-addon2"
                                min="1"
                                max="1000"
                                v-model="item.cal"
                                id="lottery_amount_0"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text" id="basic-addon2">ชุด</span>
                              </div>
                            </div>
                          </div>
                        </span>
                      </div>
                      <div class="d-flex flex-row flex-sm-row flex-md-row-reverse justify-content-between justify-content-sm-between justify-content-md-end">
                        <div class="d-flex flex-row flex-sm-row flex-md-row-reverse justify-content-between justify-content-sm-between justify-content-md-end">
                          <div class="p-1" v-if="lotteryInformation.status == 1 && !this.getIsClose(lotteryInformation.date.date_close)"><span class="badge badge-dark font-weight-light">ประจำวันที่ </span> {{ lotteryInformation.title }}</div>
                          <div class="p-1" v-else><font class="text-primary">{{ lotteryInformation.group_name ? lotteryInformation.group_name : '' }}ยังไม่เปิดกรุณารอรอบต่อไป</font></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 p-1">
                      <div class="w-100 rounded table-primary p-1 text-center">
                        ใช้ Credit ทั้งหมด
                        <h4 class="mb-0 total_price">{{ priceAll }}</h4>
                      </div>
                    </div>
                    <div class="col-6 p-1">
                      <div class="w-100 rounded table-secondary p-1 text-center">
                        Credit คงเหลือ
                        <h4 class="mb-0 my-balance">{{ getBalance }}</h4>
                      </div>
                    </div>
                    <div class="col-12 p-1">
                      <button
                        class="btn btn-success btn-block triggerSendLottery"
                        :disabled="this.priceAll === '0.0'"
                        @click="onSubmit"
                      >
                        ยืนยัน
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <lottoSetModal
      :dataModal="dataModal"
      :numberList="numberList"
      @closeLottoSet="toggle($event)"
      @trigKey="compKeygen($event)"
      v-if="showSubmit"
    />
    <app-rule-by-id-modal :advice="this.advice" />
  </div>
</template>

<script>
import moment from 'moment'
import ruleByIdModal from '@/components/modals/RuleByIdModalHtml'
import lottoSetModal from '@/components/modals/lottoSetModal'
import { submitNumberLottoServices } from '@/services'
import { mapGetters, mapActions } from 'vuex'
import * as numeral from 'numeral'
export default {
  components: {
    lottoSetModal,
    'app-rule-by-id-modal': ruleByIdModal
  },
  async created () {
    try {
      this.setNumberList([])
      this.setLoading(true)
      this.id = parseInt(this.$route.params.id)
      await this.getInquiryRoundInfomationByID(this.id)
      this.lotteryInformation = this.getInquiryRoundInfomation
      this.advice = this.lotteryInformation.advice
      this.priceLottoSet = this.lotteryInformation.my_price[8].min
      this.listSelector = [this.getInquiryRoundInfomation.my_price[0]]
      this.setLoading(false)
    } catch (error) {
      this.setLoading(false)
    }
    this.isLoading = true
  },
  data () {
    return {
      id: null,
      number: '',
      numberList: [],
      my_price: 0,
      lotteryInformation: null,
      listSelector: [],
      type: 4,
      priceLottoSet: 120,
      lotto: {},
      dataModal: '',
      showSubmit: false,
      isLoading: false,
      advice: ''
    }
  },
  methods: {
    ...mapActions(['setLoading', 'setNumberList', 'getInquiryRoundInfomationByID', 'getBalanceInfo']),
    pushNumber (numbers) {
      this.numberList = this.getNumberList
      this.number = this.number + numbers
      if (this.number.split('').length > this.type - 1) {
        const isCheck = this.numberList.map(e => parseInt(e.number)).indexOf(parseInt(this.number))
        if (isCheck === -1) {
          this.numberList.push({
            cal: '1',
            number: this.number
          })
          this.setNumberList(this.numberList)
          this.clear()
        } else {
          this.setNumberList(this.numberList)
          this.clear()
        }
      }
    },
    clear () {
      this.number = ''
    },
    clearList () {
      this.number = ''
      this.numberList = []
      this.setNumberList(this.numberList)
    },
    deleteLast () {
      this.number = this.number.substring(0, this.number.length - 1)
    },
    removeLast () {
      this.numberList.splice(-1, 1)
      this.setNumberList(this.numberList)
    },
    getIsClose (value) {
      const final = moment(value)
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = final - now
      if (timeleft > 0) {
        return false
      } else {
        return true
      }
    },
    async onSubmit () {
      const data = {
        roundID: this.id,
        type: this.numberList.map(() => 8),
        number: this.numberList.map(e => e.number),
        price: this.numberList.map(e => parseInt(e.cal) || 0)
      }
      this.dataModal = data
      this.alertDisplay()
    },
    async summitData () {
      const resp = await submitNumberLottoServices(this.dataModal)
      if (resp.status_code === 'SUCCESS') {
        const data = {
          roundID: this.id,
          type: [],
          number: [],
          price: []
        }
        this.dataModal = data
        this.clearList()
        this.$swal({
          position: 'center',
          icon: 'success',
          title: 'ทำการแทงหวยแล้ว',
          showConfirmButton: false,
          timer: 1000
        })
        await this.getBalanceInfo()
        this.setLoading(false)
      } else {
        this.setLoading(false)
      }
    },
    toggle (value) {
      this.showSubmit = value
    },
    compKeygen (value) {
      if (value) {
        this.clearList()
      }
    },
    alertDisplay () {
      let text_alert = ''
      this.dataModal.number.forEach((value, index) => {
        text_alert += '<strong>' + value + '</strong> จำนวน ' + this.dataModal.price[index] + ' ชุด<br>'
      })
      text_alert += 'รวมเป็นเงิน ' + this.priceAll + ' บาท'
      this.$swal({
        title: 'คุณต้องการแทง: ' + this.lotteryInformation.group_name + ' ?',
        html: text_alert,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยันการแทง',
        cancelButtonText: 'ปิด'
      }).then((result) => {
        if (result.isConfirmed) {
          this.setLoading(true)
          this.summitData()
        }
      })
    }
  },
  computed: {
    ...mapGetters(['getInquiryRoundInfomation', 'getLotteryList', 'getNumberList', 'getBalance']),
    priceAll () {
      return numeral(this.numberList.reduce((a, b) => a + parseInt(b.cal), 0) * 120 || 0).format('0,0.0')
    }
  }
}
</script>

<style>
</style>

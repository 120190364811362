<template>
  <div id="lottoSet" class="pagemodal-wrapper open">
    <div class="container">
      <div class="pagemodal">
        <div class="head">
          <a class="btn-close" @click="closeLottoSet" style="cursor: pointer"></a>
        </div>
        <div class="content submit-lottoset" style="justify-items: center; text-align: center">
          <img
            src="@/assets/infoicon.png"
            class="mb-2"
            style="
              width: 10vh;
              heigth: 10vh;
              filter: sepia(90%) saturate(6002%) hue-rotate(355deg) brightness(108%) contrast(107%);
            "
          />
          <h3>ยืนยันการแทง</h3>
          <div v-for="(item, index) in numberList" :key="index">
            <span style="font-size: 2vh">{{ item.number }} จำนวน {{ item.cal }} ชุด</span>
          </div>
          <p style="font-size: 2.5vh; color: green">รวมเป็นเงิน {{ priceAll }} บาท</p>
          <div class="content-button">
            <button class="btn btn-success" @click="onSubmit">ยืนยัน</button>
            <button class="btn btn-secondary" @click="closeLottoSet">ปิด</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { submitNumberLottoServices } from '@/services'
import { mapActions } from 'vuex'
import * as numeral from 'numeral'
export default {
  props: {
    dataModal: Object,
    numberList: Array
  },
  computed: {
    priceAll () {
      return numeral(this.numberList.reduce((a, b) => a + parseInt(b.cal), 0) * 120 || 0).format('0,0.0')
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    closeLottoSet () {
      this.$emit('closeLottoSet', false)
    },
    async onSubmit () {
      try {
        this.setLoading(true)
        const resp = await submitNumberLottoServices(this.dataModal)
        if (resp.status_code === 'SUCCESS') {
          this.$emit('closeLottoSet', false)
          this.$emit('trigKey', true)
        }
      } catch (error) {}
      this.setLoading(false)
    }
  }
}
</script>

<style scoped>
#lottoSet {
  color: #595959;
  display: flex;
  align-items: center;
}
.pagemodal .content {
  height: 50vh;
  position: relative !important;
  z-index: 10;
}
.submit-lottoset {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.content-button {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}
#lottoSet button {
  padding: 0.5rem 2rem;
}
</style>
